import { useLayoutEffect } from 'react';

import { getWithExpiry, setWithExpiry } from 'common/helpers/localStorageWithExpiry';

export function ErrorFallback({ error }: any) {
  // Reset reload counter after successful launch
  useLayoutEffect(() => {
    const chunkErrorMessages = [
      /Loading chunk [\d]+ failed/,
      /Failed to fetch dynamically imported module/,
      /Unable to preload CSS/,
    ];

    const isChunkError = chunkErrorMessages.some((pattern) =>
      pattern.test(error?.message)
    );

    if (isChunkError && !getWithExpiry('chunk_failed')) {
      setWithExpiry('chunk_failed', 'true', 10000);
      window.location.reload();
    }
  }, [error]);

  return (
    <div>
      <p>Something went wrong</p>
      <pre>{error?.message}</pre>
    </div>
  );
}
