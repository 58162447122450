import { lazy } from 'react';
import { FC } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

const FleetManagement = lazy(() => import('./fleet-management/index'));
const BusConfigs = lazy(() => import('BusConfigsWidget/index'));
const BusConfigsAndLayouts = lazy(() => import('BusConfigsAndLayoutsWidget/index'));
const BusLayouts = lazy(() => import('BusLayoutsWidget/index'));
const TaurusLiteWidget = lazy(() => import('TaurusLiteWidget/index'));
const TaurusLiteWidgetPanel = lazy(() => import('TaurusLiteWidgetPanel/index'));

export const Routes: FC = () => {
  return (
    <RouterRoutes>
      <Route
        path={'bus-configs-layouts-widget/:id/*'}
        element={<BusConfigsAndLayouts />}
      />
      <Route path={'bus-configs-widget/:id/*'} element={<BusConfigs />} />
      <Route path={'bus-layouts-widget/:id/*'} element={<BusLayouts />} />
      <Route path={'taurus-lite-widget/:id/*'} element={<TaurusLiteWidget />} />
      <Route path={'taurus-lite-popup/:id/*'} element={<TaurusLiteWidgetPanel />} />
      <Route path={'*'} element={<FleetManagement />} />
    </RouterRoutes>
  );
};
