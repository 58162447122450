import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorFallback } from 'common/components/ErrorFallback';

import { Routes } from './Routes';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Router>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={null}>
        <Routes />
      </Suspense>
    </ErrorBoundary>
  </Router>
);
